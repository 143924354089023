import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Button, Input } from 'antd';
import '../assets/scss/search.scss';
import { parse } from 'qs';
import { useDebounce } from '../utils';
const DEBOUNCE_TIME = 500;
const ENTER = 'Enter';
function Search() {
  const [query, setQuery] = useState('');

  const handleChange = e => {
    const value = e.target.value;
    setQuery(value.trim());
  };

  useEffect(() => {
    if (window) {
      const { keyword } = parse(window.location.search.split('?')[1]);
      setQuery(keyword || '');
    }
  }, []);

  const handleSearch = useDebounce(
    () => {
      navigate(`/searchPage/?keyword=${encodeURIComponent(query)}`);
    },
    DEBOUNCE_TIME,
    [query]
  );

  const handleKeyPress = e => {
    const { code } = e;
    if (code === ENTER) {
      handleSearch();
    }
  };

  return (
    <div className="search-wrapper">
      <Input
        className="search-input"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={query}
      />
      <Button className="btn" onClick={handleSearch}>
        搜索课程
      </Button>
    </div>
  );
}

export default Search;
