import { useEffect, useRef, useCallback } from 'react';
import { httpPost } from '../config/fetch';
import { getUserCourse } from '../config/api';
import MD5 from 'md5';
const LEJU = 'LEJU';
const TokenString = '52KY4A0';

export const generateTimeStamp = () => Math.floor(new Date().getTime() / 1000);

export const generateSecretToken = () =>
  MD5(LEJU + generateTimeStamp() + TokenString);

export const generatePassword = password => MD5(password);

export const useDebounce = (fn, delay, dep = []) => {
  const { current } = useRef({ fn, timer: null });
  useEffect(
    function () {
      current.fn = fn;
    },
    [fn]
  );

  return useCallback(function f(...args) {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.fn(...args);
    }, delay);
  }, dep);
};

export const userCourseListRequest = async (email, fn) => {
  try {
    const { resCode, userCourseList } = await httpPost(getUserCourse, {
      userEmail: email
    });
    fn(resCode === 0 ? userCourseList : []);
  } catch (err) {
    fn([]);
  }
};

export const EXCHANGE_TYPE = 1;

export const passwordRegRule = {
  required: true,
  pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
  message: '请设置6-20个包含英文+数字字符组成的密码'
};

export const emailRequiredRule = {
  required: true,
  whitespace: true,
  message: '请输入邮箱账号'
};

export const emailRegRule = {
  pattern: /^[a-zA-Z0-9][\w.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w.-]*[a-zA-Z0-9].[a-zA-Z][a-zA-Z.]*[a-zA-Z]$/,
  message: '请输入正确的邮箱账号'
};
