import React, { useState } from 'react';
import { Modal, Button, message, Form } from 'antd';
import '../assets/scss/modal.scss';
import Email from './Email';
import Password from './Password';
import { httpPost } from '../config/fetch';
import { login } from '../config/api';
import { generatePassword, userCourseListRequest, passwordRegRule } from '../utils';

function Login({
  visible,
  showLoginStatus,
  setUserInfo,
  showRegistryStatus,
  showResetPasswordStatus,
  setUserCourseList
}) {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const hideModal = () => {
    showLoginStatus(false);
    setBtnLoading(false);
    form.resetFields();
  };

  const onFinish = values => {
    const { email, password } = values;
    const params = {
      password: generatePassword(password),
      userEmail: email
    };
    setBtnLoading(true);
    httpPost(login, params)
      .then(response => {
        setBtnLoading(false);
        const { resCode, userToken } = response;
        if (resCode === 0) {
          message.success('登录成功');
          setUserInfo({
            email,
            userToken
          });
          userCourseListRequest(email, setUserCourseList);
          hideModal();
        } else if (resCode === 3) {
          message.error('用户不存在');
        } else if (resCode === 5) {
          message.error('密码错误');
        }
      })
      .catch(() => {
        setBtnLoading(false);
      });
  };

  const goToRegistry = () => {
    hideModal();
    showRegistryStatus(true);
  };

  const goToResetPassword = () => {
    hideModal();
    showResetPasswordStatus(true);
  };

  return (
    <Modal
      className="modal-wrapper"
      width={420}
      visible={visible}
      onCancel={hideModal}
      centered={true}
      footer={null}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
    >
      <Form form={form} validateTrigger={[]} onFinish={onFinish}>
        <p className="modal-title">登录账号</p>
        <Email />
        <Password />
        <Form.Item>
          <Button
            loading={btnLoading}
            className="modal-btn login-btn"
            type="primary"
            htmlType="submit"
          >
            登录
          </Button>
        </Form.Item>
        <div className="modal-bottom">
          <span className="registry" onClick={goToRegistry}>
            免费注册
          </span>
          <span className="password" onClick={goToResetPassword}>
            忘记密码
          </span>
        </div>
      </Form>
    </Modal>
  );
}

export default Login;
