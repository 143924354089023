import React, { Fragment, useEffect, useState, memo, useRef } from 'react';
import { Modal } from 'antd';
import '../assets/scss/popup-box.scss';
import Complete from '../assets/image/complete_icon.png';
import Error from '../assets/image/error_icon.png';
const DEFAULT_HIDE_TIME = 2000;

const PopupBox = ({
  visible,
  width = 280,
  text,
  status = 'complete',
  hideTime,
  onChange
}) => {
  const complete = text => {
    return (
      <Fragment>
        <img className="img" src={Complete} />
        {text && <p className="text">{text}</p>}
      </Fragment>
    );
  };
  const error = text => {
    return (
      <Fragment>
        <img className="img" src={Error} />
        {text && <p className="text">{text}</p>}
      </Fragment>
    );
  };
  const [showStatus, setShowStatus] = useState(false);
  const timer = useRef(null);
  const list = {
    complete,
    error
  };
  const hideModal = () => {
    setShowStatus(false);
    onChange(false);
  };
  useEffect(() => {
    setShowStatus(visible);
    if (visible) {
      timer.current = setTimeout(() => {
        hideModal();
      }, hideTime || DEFAULT_HIDE_TIME);
    }
    return () => {
      if (!visible) clearTimeout(timer.current);
    };
  }, [visible]);
  return (
    <Modal
      width={width}
      bodyStyle={{
        width
      }}
      wrapClassName="popup-box-wrapper"
      centered
      visible={showStatus}
      footer={null}
      closable={true}
      onCancel={hideModal}
      destroyOnClose={true}
    >
      {status && list[status](text)}
    </Modal>
  );
};

export default memo(PopupBox);
