import {
  SHOW_LOGIN_STATUS,
  SET_USET_INFO,
  SHOW_REGISTRY_STATUS,
  SHOW_RESET_PASSWORD_STATUS,
  SHOW_CHANGE_PASSWORD_STATUS,
  SHOW_REDEEM_CODE_STATUS,
  SHOW_SCAN_TO_PAY_STATUS,
  SET_READ_VIDEO_INFO,
  SET_USER_COURSE_LIST
} from './types';

export const showLoginStatus = (status) => ({
  type: SHOW_LOGIN_STATUS,
  status
});

export const showRegistryStatus = (status) => ({
  type: SHOW_REGISTRY_STATUS,
  status
});

export const showResetPasswordStatus = (status) => ({
  type: SHOW_RESET_PASSWORD_STATUS,
  status
});

export const showChangePasswordStatus = (status) => ({
  type: SHOW_CHANGE_PASSWORD_STATUS,
  status
});

export const showRedeemCodeStatus = (status) => ({
  type: SHOW_REDEEM_CODE_STATUS,
  status
});

export const showScanToPayStatus = (status) => ({
  type: SHOW_SCAN_TO_PAY_STATUS,
  status
});

export const setUserInfo = (userInfo) => ({
  type: SET_USET_INFO,
  userInfo
});

export const setReadVideoInfo = (readVideoInfo) => ({
  type: SET_READ_VIDEO_INFO,
  readVideoInfo
});

export const setUserCourseList = (userCourseList) => ({
  type: SET_USER_COURSE_LIST,
  userCourseList
});

