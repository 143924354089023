import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import Password from './Password';
import '../assets/scss/modal.scss'
import { httpPost } from '../config/fetch'
import { updatePassword } from '../config/api'
import { generatePassword } from '../utils'
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      oldPassword: '',
      passwordStatus: true,
      oldPasswordStatus: true,
      btnLoading: false
    };
  }

  hideModal = () => {
    const { showChangePasswordStatus } = this.props;
    showChangePasswordStatus(false);
    this.setState({
      password: '',
      oldPassword: '',
      passwordStatus: true,
      newPasswordStatus: true,
      btnLoading: false
    })
  };

  onSubmit = () => {
    const { userInfo, setUserInfo, showLoginStatus } = this.props;
    const { password, oldPassword } = this.state;
    if (password && oldPassword) {
      const params = {
        userEmail: userInfo.email,
        oldPassword: generatePassword(oldPassword),
        newPassword: generatePassword(password)
      }
      this.setState({ btnLoading: true })
      httpPost(updatePassword, params)
        .then(response => {
          this.setState({ btnLoading: false })
          const { resCode } = response
          if (resCode === 0) {
            message.success('修改成功')
            this.hideModal();
            setUserInfo({});
            showLoginStatus(true)
          } else if (resCode === 7) {
            message.error('修改密码失败')
            this.hideModal();
            setUserInfo({});
          } else if (resCode === 5) {
            message.error('旧密码输入错误，请重新输入')
          }
        })
        .catch(() => this.setState({ btnLoading: false }))
    }
  };

  handlePasswordChange = (value) => {
    this.setState({ password: value })
  }

  handleOldPasswordChange = (value) => {
    this.setState({ oldPassword: value })
  }

  validatorPassword = (status) => {
    this.setState({ passwordStatus: !status });
  }

  validatorOldPassword = (status) => {
    this.setState({ oldPasswordStatus: !status });
  }

  render() {
    const { visible } = this.props;
    const { passwordStatus, oldPasswordStatus, oldPassword, password, btnLoading } = this.state;
    return (
      <Modal
        className="modal-wrapper"
        width={420}
        visible={visible}
        onCancel={this.hideModal}
        centered={true}
        footer={null}
        keyboard={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className="modal-reset-password">
          <div className="modal-title">
            <p>修改密码</p>
            <p className="modal-title--tip">请先验证旧密码</p>
          </div>
          <Password placeholder="请输入旧密码" value={oldPassword} onChange={this.handleOldPasswordChange} onBlur={this.validatorOldPassword} className="modal-input" />
          <Password placeholder="请输入新密码" value={password} onChange={this.handlePasswordChange} onBlur={this.validatorPassword} className="modal-input" />
          <Button loading={btnLoading} disabled={passwordStatus || oldPasswordStatus} className="modal-btn registry-btn" type="primary" htmlType="submit" onClick={this.onSubmit}>
            确定
          </Button>
        </div>
      </Modal>
    )
  }
}

export default ResetPassword;
