import axios from 'axios';
import qs from 'qs';
import { generateTimeStamp, generateSecretToken } from '../utils';
import { apiConfigurationUrl } from './env'

axios.defaults.timeout = 100000;
axios.defaults.baseURL = apiConfigurationUrl;

axios.interceptors.request.use(
  config => {
    config.data = qs.stringify({
      timestamp: generateTimeStamp(),
      secretToken: generateSecretToken(),
      ...config.data
    })
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    const userInfo = localStorage.userInfo; 
    if(userInfo) {
      config.headers['user-token'] = JSON.parse(userInfo).userToken
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function httpGet(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function httpPost(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}