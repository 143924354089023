import React, { useState } from 'react';
import { Button, Form } from 'antd';
import EmailVerifyCode from './EmailVerifyCode';
import { httpPost } from '../config/fetch';
import { isVerifyCodeValid } from '../config/api';

function VerifyEmail({ setUserInfo, onClick }) {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [userToken, setUserToken] = useState('');

  const onFinish = values => {
    const { email } = values;
    setUserInfo({
      userEmail: email,
      userToken
    });
    onClick(2);
  };

  const emailValidator = async (_, verifyCode) => {
    try {
      setBtnLoading(true);
      const email = form.getFieldValue('email');
      if (email && verifyCode) {
        const params = {
          userEmail: email,
          userVerifyCode: verifyCode
        };
        const response = await httpPost(isVerifyCodeValid, params);
        const { resCode, codeStatus, userToken: token } = response;
        if (resCode === 0 && codeStatus) {
          setBtnLoading(false);
          setUserToken(token);
          return Promise.resolve();
        }
      }
    } catch (error) {}
    setBtnLoading(false);
    return Promise.reject(new Error('请输入正确的验证码'));
  };

  return (
    <div className="verify-email">
      <Form form={form} validateTrigger={[]} onFinish={onFinish}>
        <EmailVerifyCode form={form} emailValidator={emailValidator} />
        <Form.Item>
          <Button
            loading={btnLoading}
            className="modal-btn registry-btn"
            type="primary"
            htmlType="submit"
          >
            下一步
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default VerifyEmail;
