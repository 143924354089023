import React, { Fragment } from 'react';
import { Input, Form } from 'antd';
import Email from './Email';
import SendVerifyCode from './SendVerifyCode';

function EmailVerifyCode({ form, emailValidator }) {
  const verifyEmail = async () => {
    try {
      const { email } = await form.validateFields(['email']);
      return email;
    } catch (error) {
      return false;
    }
  };

  return (
    <Fragment>
      <Email />
      <Form.Item>
        <Form.Item
          name="verifyCode"
          noStyle
          rules={[
            {
              validator: emailValidator
            }
          ]}
        >
          <Input placeholder="验证码" className="modal-verificationCode" />
        </Form.Item>
        <SendVerifyCode
          verifyEmail={verifyEmail}
          className="modal-verificationCode-btn"
        />
      </Form.Item>
    </Fragment>
  );
}

export default EmailVerifyCode;
