import React, { useState } from 'react';
import { Modal, Form, Button, message } from 'antd';
import EmailVerifyCode from './EmailVerifyCode';
import Password from './Password';
import '../assets/scss/modal.scss';
import { httpPost } from '../config/fetch';
import { register, isVerifyCodeValid } from '../config/api';
import { generatePassword, userCourseListRequest } from '../utils';

function Registry({
  visible,
  showRegistryStatus,
  setUserInfo,
  showLoginStatus,
  setUserCourseList
}) {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);

  const hideModal = () => {
    showRegistryStatus(false);
    setBtnLoading(false);
    form.resetFields();
  };

  const onFinish = values => {
    const { email, password, verifyCode } = values;
    const params = {
      password: generatePassword(password),
      userEmail: email,
      verifyCode
    };
    setBtnLoading(true);
    httpPost(register, params)
      .then(response => {
        setBtnLoading(false);
        const { resCode, userToken } = response;
        if (resCode === 0) {
          message.success('注册成功，已自动为您登录');
          setUserInfo({
            email,
            userToken
          });
          hideModal();
          userCourseListRequest(email, setUserCourseList);
        } else if (resCode === 4) {
          message.error('该账号已存在');
        } else if (resCode === 6) {
          message.error('验证码错误');
        }
      })
      .catch(() => {
        setBtnLoading(false);
      });
  };

  const emailValidator = async (_, verifyCode) => {
    setBtnLoading(true);
    try {
      const email = form.getFieldValue('email');
      if (email && verifyCode) {
        const params = {
          userEmail: email,
          userVerifyCode: verifyCode
        };
        const response = await httpPost(isVerifyCodeValid, params);
        const { resCode, codeStatus } = response;
        if (resCode === 0 && codeStatus) {
          setBtnLoading(false);
          return Promise.resolve();
        }
      }
    } catch (error) {}
    setBtnLoading(false);
    return Promise.reject(new Error('请输入正确的验证码'));
  };

  const goToLogin = () => {
    hideModal();
    showLoginStatus(true);
  };

  return (
    <Modal
      className="modal-wrapper"
      width={420}
      visible={visible}
      onCancel={hideModal}
      centered={true}
      footer={null}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className="modal-registry">
        <Form form={form} validateTrigger={[]} onFinish={onFinish}>
          <div className="modal-title">
            <p>邮箱注册</p>
            <p className="modal-title--tip">未注册邮箱验证后即可完成注册</p>
          </div>
          <EmailVerifyCode form={form} emailValidator={emailValidator} />
          <Password placeholder="设置密码，需包括英文和数字" />
          <Form.Item>
            <Button
              loading={btnLoading}
              className="modal-btn login-btn"
              type="primary"
              htmlType="submit"
            >
              注册
            </Button>
          </Form.Item>
        </Form>
        <div className="registry-bottom">
          <span className="login" onClick={goToLogin}>
            去登陆
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default Registry;
