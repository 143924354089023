import React, { Component } from 'react';
import { Button, message, Form } from 'antd';
import Password from './Password';
import { httpPost } from '../config/fetch';
import { resetPassword } from '../config/api';
import { generatePassword } from '../utils';
class VerifyPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoading: false
    };
  }

  onFinish = values => {
    const {
      setUserInfo,
      userInfo,
      showLoginStatus,
      showResetPasswordStatus
    } = this.props;
    const { password, newPassword } = values;
    if (password && newPassword) {
      const params = {
        userEmail: userInfo.userEmail,
        newPassword: generatePassword(newPassword)
      };
      this.setState({ btnLoading: true });
      httpPost(resetPassword, params)
        .then(response => {
          this.setState({ btnLoading: false });
          const { resCode } = response;
          if (resCode === 0) {
            setUserInfo({});
            showResetPasswordStatus(false);
            showLoginStatus(true);
          } else if (resCode === 3) {
            message.error('用户不存在');
          } else if (resCode === 7) {
            message.error('重置密码失败');
          }
        })
        .catch(() => {
          this.setState({ btnLoading: false });
        });
    }
  };

  render() {
    const { btnLoading } = this.state;
    return (
      <div className="verify-email">
        <Form validateTrigger={[]} onFinish={this.onFinish}>
          <Password placeholder="新密码" />
          <Password
            placeholder="再次输入新密码"
            name="newPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次输入的密码不一致'));
                }
              })
            ]}
          />
          <Form.Item>
            <Button
              loading={btnLoading}
              className="modal-btn registry-btn"
              type="primary"
              htmlType="submit"
            >
              完成
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default VerifyPassword;
