import React from 'react';
import { Form, Input } from 'antd';
import { passwordRegRule } from '../utils';

function Password({ className, name, rules, placeholder }) {
  return (
    <Form.Item name={name} rules={rules}>
      <Input.Password className={className} placeholder={placeholder} />
    </Form.Item>
  );
}

export default Password;

Password.defaultProps = {
  name: 'password',
  className: 'modal-input',
  rules: [passwordRegRule],
  placeholder: '密码'
};
