import React, { Fragment, useCallback, useState } from 'react';
import { Modal, Button, Input } from 'antd';
import '../assets/scss/modal.scss';
import { httpPost } from '../config/fetch';
import { exchangeCourseByCode } from '../config/api';
import { userCourseListRequest } from '../utils';
import PopupBox from './PopupBox';
const ERROR = 'error';
const COMPLETE = 'complete';
function RedeemCode({
  showRedeemCodeStatus,
  visible,
  userInfo,
  courseId,
  setUserCourseList
}) {
  const [code, setCode] = useState('');
  const [popupBox, setPopupBox] = useState({
    visible: false,
    status: null,
    text: ''
  });
  const [loading, setLoading] = useState(false);
  const handleChange = e => {
    const value = e.target.value;
    setCode(value.trim());
  };

  const hideModal = () => {
    setCode('');
    showRedeemCodeStatus(false);
  };

  const handleSure = useCallback(() => {
    if (!code) return;
    const params = {
      userEmail: userInfo.email,
      courseCode: code
    };
    if (courseId) params.courseId = courseId;
    setLoading(true);
    httpPost(exchangeCourseByCode, params)
      .then(res => {
        setLoading(false);
        const { resCode, courseName } = res;
        const list = {
          0: {
            visible: true,
            status: COMPLETE,
            text: (
              <Fragment>
                <span style={{ color: '#f8be36' }}>{courseName}</span>
                已兑换成功
              </Fragment>
            ),
            fn: () => {
              userCourseListRequest(userInfo.email, setUserCourseList);
              hideModal();
            }
          },
          10: {
            visible: true,
            status: ERROR,
            text: '您已兑换过该课程'
          },
          11: {
            visible: true,
            status: ERROR,
            text: '请输入正确的兑换码'
          },
          12: {
            visible: true,
            status: ERROR,
            text: '您输入的验证码非本课程，请前往相应的页面兑换课程'
          }
        };
        setPopupBox(list[resCode]);
        list[resCode]?.fn();
      })
      .catch(() => {
        setLoading(false);
      });
  }, [code]);

  const onChange = visible => {
    setPopupBox({ ...popupBox, visible });
  };

  return (
    <Fragment>
      <Modal
        className="modal-wrapper"
        width={420}
        visible={visible}
        onCancel={hideModal}
        centered={true}
        footer={null}
        keyboard={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className="modal-redeem-code">
          <div className="modal-title default">
            <p>请输入兑换码</p>
          </div>
          <Input
            placeholder="请输入兑换码"
            value={code}
            onChange={handleChange}
            className="modal-input"
          />
          <div className="btns">
            <Button className="btn default" onClick={hideModal}>
              取消
            </Button>
            <Button className="btn" loading={loading} onClick={handleSure}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
      <PopupBox {...popupBox} onChange={onChange} />
    </Fragment>
  );
}

export default RedeemCode;
