import React, { Component } from 'react';
import { Button, message } from 'antd';
import '../assets/scss/sendVerifyCode.scss';
import { getNewVerifyCode } from '../config/api'
import { httpPost } from '../config/fetch';
class SendVerifyCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      status: true,
      count: props.count
    }
  }

  componentWillUnmount() {
    this.handleReset();
  }

  sendVerifyCode = () => {
    const { count: initCount, text: initText, joinText } = this.props;
    const { count } = this.state;
    if (count === 1) {
      this.setState({
        count: initCount,
        status: true,
        text: initText
      })
    } else {
      this.setState({
        count: count - 1,
        status: false,
        text: count - 1 + joinText
      })
      this.timer = setTimeout(() => this.sendVerifyCode(), 1000)
    }
  }

  handleClick = async () => {
    const { status } = this.state;
    const { verifyEmail } = this.props;
    const email = await verifyEmail();
    if (!email || !status) return;
    const params = {
      userEmail: email
    }
    httpPost(getNewVerifyCode, params).then(res => {
      const { resCode } = res;
      if (resCode === 0) {
        message.success(`已发送验证码`);
      } else if (resCode === 1 || resCode === 2) {
        message.error(`验证码发送失败`);
      }
    })
    this.sendVerifyCode();
  }

  handleReset = () => {
    const { count: initCount, text: initText } = this.props;
    this.setState({
      count: initCount,
      status: true,
      text: initText
    })
    this.timer && clearTimeout(this.timer);
  }

  render() {
    const { text, status } = this.state;
    const { className, style, disabled } = this.props;
    return (
      <Button
        className={`${className} verificationCode-btn`}
        style={style}
        disabled={disabled || !status}
        onClick={this.handleClick}
      >
        {text}
      </Button>
    )
  }
}

export default SendVerifyCode;

SendVerifyCode.defaultProps = {
  disabled: false,
  count: 60,
  text: '获取验证码',
  joinText: '后重新获取'
}