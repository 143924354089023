import React, { Fragment, useEffect, useState, memo } from 'react';
import { Link } from 'gatsby';
import { Dropdown, Menu, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Search from './Search';
import '../assets/scss/header.scss';
import Logo from '../assets/image/logo.png';
const DETAIL_PAGE = '/course-detail';
const Header = ({
  showLoginStatus,
  showChangePasswordStatus,
  showRegistryStatus,
  showRedeemCodeStatus,
  setUserInfo,
  setReadVideoInfo,
  setUserCourseList,
  userInfo
}) => {
  const [pathname, setPathName] = useState('');
  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);
  const handleLogin = () => {
    showLoginStatus(true);
  };

  const handleLogout = () => {
    setUserInfo({});
    setReadVideoInfo({});
    setUserCourseList([]);
  };

  const handleChangePassword = () => {
    showChangePasswordStatus(true);
  };

  const handleRegistry = () => {
    showRegistryStatus(true);
  };

  const handleRedeem = () => {
    if (!userInfo.email) {
      return message.info(`请先登录账号`);
    }
    showRedeemCodeStatus(true);
  };

  const renderDropdown = () => {
    const menu = (
      <Menu className="dropdown-menu">
        <Menu.Item onClick={handleLogout}>
          <span>退出登录</span>
        </Menu.Item>
        <Menu.Item onClick={handleChangePassword}>
          <span>修改密码</span>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={menu}>
        <div className="text cursor">
          <span>{userInfo.email}</span>
          <DownOutlined className="icon" />
        </div>
      </Dropdown>
    );
  };

  return (
    <div className="header">
      <div className="container">
        <Link to="/">
          <img className="logo" src={Logo} alt="logo" />
        </Link>
        <Search />
        <div className="right">
          {pathname !== DETAIL_PAGE && (
            <div
              className="redeem cursor"
              onKeyDown={handleRedeem}
              onClick={handleRedeem}
            >
              兑换课程
            </div>
          )}
          {userInfo.email ? (
            <Fragment>{renderDropdown()}</Fragment>
          ) : (
            <Fragment>
              <span
                className="text cursor"
                role="button"
                onKeyDown={handleRegistry}
                onClick={handleRegistry}
              >
                注册
              </span>
              <span className="text">|</span>
              <span
                className="text cursor"
                role="button"
                onKeyDown={handleLogin}
                onClick={handleLogin}
              >
                登录
              </span>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
