const basePrefix = 'api'
export const isVerifyCodeValid = basePrefix + '/isVerifyCodeValid';
export const register = basePrefix + '/register';
export const getNewVerifyCode = basePrefix + '/getNewVerifyCode';
export const login = basePrefix + '/login';
export const resetPassword = basePrefix + '/resetPassword';
export const updatePassword = basePrefix + '/updatePassword';
export const getCourseList = basePrefix + '/getCourseList';
export const getCourseDetail = basePrefix + '/getCourseDetail';
export const exchangeCourseByCode = basePrefix + '/exchangeCourseByCode';
export const getUserCourse = basePrefix + '/getUserCourse';
export const getVideoList = basePrefix + '/getVideoList';
