import React from 'react';
import { Form, Input } from 'antd';
import { emailRequiredRule, emailRegRule } from '../utils';

function Email({ className, name, rules, placeholder }) {
  return (
    <Form.Item name={name} rules={rules}>
      <Input className={className} placeholder={placeholder} />
    </Form.Item>
  );
}

export default Email;

Email.defaultProps = {
  name: 'email',
  className: 'modal-input',
  rules: [emailRequiredRule, emailRegRule],
  placeholder: '邮箱账号'
};
