/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import Header from './header';
import Footer from './Footer';
import Login from './Login';
import Registry from './Registry';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';
import RedeemCode from './RedeemCode';
import '../assets/css/normalize.css';
import '../assets/scss/common.scss';
import {
  showRedeemCodeStatus,
  showLoginStatus,
  showRegistryStatus,
  showResetPasswordStatus,
  showChangePasswordStatus,
  setUserInfo,
  setUserCourseList,
  setReadVideoInfo
} from '../state/actions';
message.config({
  maxCount: 1
});
const Layout = ({
  children,
  showLoginStatus,
  loginStatus,
  showRegistryStatus,
  registryStatus,
  resetPasswordStatus,
  showResetPasswordStatus,
  changePasswordStatus,
  showChangePasswordStatus,
  redeemCodeStatus,
  showRedeemCodeStatus,
  setUserInfo,
  userInfo,
  courseId,
  setUserCourseList,
  setReadVideoInfo
}) => {
  return (
    <React.Fragment>
      <Header
        showLoginStatus={showLoginStatus}
        showRegistryStatus={showRegistryStatus}
        showChangePasswordStatus={showChangePasswordStatus}
        showRedeemCodeStatus={showRedeemCodeStatus}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        setUserCourseList={setUserCourseList}
        setReadVideoInfo={setReadVideoInfo}
      />
      <div className="page-wrapper">{children}</div>
      <Footer />
      <Login
        visible={loginStatus}
        showLoginStatus={showLoginStatus}
        showRegistryStatus={showRegistryStatus}
        showResetPasswordStatus={showResetPasswordStatus}
        setUserInfo={setUserInfo}
        setUserCourseList={setUserCourseList}
      />
      <Registry
        visible={registryStatus}
        showLoginStatus={showLoginStatus}
        showRegistryStatus={showRegistryStatus}
        setUserInfo={setUserInfo}
        setUserCourseList={setUserCourseList}
      />
      <ResetPassword
        visible={resetPasswordStatus}
        showLoginStatus={showLoginStatus}
        showResetPasswordStatus={showResetPasswordStatus}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
      <ChangePassword
        visible={changePasswordStatus}
        showLoginStatus={showLoginStatus}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        showChangePasswordStatus={showChangePasswordStatus}
      />
      <RedeemCode
        visible={redeemCodeStatus}
        showRedeemCodeStatus={showRedeemCodeStatus}
        userInfo={userInfo}
        courseId={courseId}
        setUserCourseList={setUserCourseList}
      />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => {
  return {
    redeemCodeStatus: state.redeemCodeStatus,
    loginStatus: state.loginStatus,
    registryStatus: state.registryStatus,
    resetPasswordStatus: state.resetPasswordStatus,
    changePasswordStatus: state.changePasswordStatus,
    userInfo: state.userInfo
  };
};

function mapDispatchToProps(dispatch) {
  return {
    showRedeemCodeStatus: status => {
      dispatch(showRedeemCodeStatus(status));
    },
    showLoginStatus: status => {
      dispatch(showLoginStatus(status));
    },
    showRegistryStatus: status => {
      dispatch(showRegistryStatus(status));
    },
    showResetPasswordStatus: status => {
      dispatch(showResetPasswordStatus(status));
    },
    showChangePasswordStatus: status => {
      dispatch(showChangePasswordStatus(status));
    },
    setUserInfo: userInfo => {
      dispatch(setUserInfo(userInfo));
    },
    setUserCourseList: userCourseList => {
      dispatch(setUserCourseList(userCourseList));
    },
    setReadVideoInfo: readVideoInfo => {
      dispatch(setReadVideoInfo(readVideoInfo));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
