import React, { Component } from 'react';
import { Modal } from 'antd';
import VerifyEmail from './VerifyEmail';
import VerifyPassword from './VerifyPassword';
import '../assets/scss/modal.scss'

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 1
    };
  }

  hideModal = () => {
    const { showResetPasswordStatus, setUserInfo } = this.props;
    showResetPasswordStatus(false);
    setUserInfo({});
    this.setState({ current: 1 });
  };

  changeCurrentStep = (current) => {
    this.setState({ current })
  }

  render() {
    const { visible, setUserInfo, userInfo, showLoginStatus, showResetPasswordStatus } = this.props;
    const { current } = this.state;
    return (
      <Modal
        className="modal-wrapper"
        width={420}
        visible={visible}
        onCancel={this.hideModal}
        centered={true}
        footer={null}
        keyboard={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className="modal-reset-password">
          <div className="modal-title">
            <p>重置密码</p>
            {
              current === 1 && <p className="modal-title--tip">请先验证邮箱</p>
            }
          </div>
          <div className="step-wrapper">
            <span className={`${current === 1 ? 'active' : ''} step`} ></span>
            <span className={`${current === 2 ? 'active' : ''} step`} ></span>
          </div>
          {current === 1 && <VerifyEmail setUserInfo={setUserInfo} onClick={this.changeCurrentStep} />}
          {current === 2 && <VerifyPassword
            showResetPasswordStatus={showResetPasswordStatus}
            showLoginStatus={showLoginStatus}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
          />}
        </div>
      </Modal>
    )
  }
}

export default ResetPassword;
